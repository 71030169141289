
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import KTModalCard from "@/components/cards/CardMainAccount.vue";
import KTNewAccountModal from "@/components/modals/forms/NewAccountModal.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTModalCard,
    KTNewAccountModal,
  },
  props: {
    widgetClasses: String,
  },
  methods: {
    editAccount(item) {
      // this.$router.push({ path: "/editmainaccount" });
      this.$router.push({ path: "/editmainaccount/" + item.id });
    },
    createAccount() {
      // this.$router.push({ path: "/createmainaccount" });
    },
    fetchData() {
      this.$store.dispatch(Actions.GET_ACCOUNT).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          const item = data.data[i];
          if (item.active === 1) {
            data.data[i].status = "Active";
            data.data[i].state = "success";
          } else {
            data.data[i].status = "Inactive";
            data.data[i].state = "white";
          }
        }
        this.mainAccounts = data.data;
      });
    },
    // switchActive (item)  {
    //   const data = await this.$store.dispatch(Actions.SET_ACCOUNT_ACTIVE, item);
    //   // update local value
    //   for (let i = 0; i < this.mainAccounts.value.length; i++) {
    //     if (copyAccounts.value[i].id === item.id) {
    //       // switch active
    //       if (copyAccounts.value[i].active === 0) {
    //         copyAccounts.value[i].active = 1;
    //         copyAccounts.value[i].status = "Active";
    //         copyAccounts.value[i].state = "success";
    //       } else {
    //         copyAccounts.value[i].active = 0;
    //         copyAccounts.value[i].status = "Inactive";
    //         copyAccounts.value[i].state = "white";
    //       }
    //     }
    //   }
    // };
  },
  setup() {
    const store = useStore();
    const checked = ref(false);
    const mainAccounts = ref(null);

    function fetchData() {
      store.dispatch(Actions.GET_ACCOUNT).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          const item = data.data[i];
          if (item.active === 1) {
            data.data[i].status = "Active";
            data.data[i].state = "success";
          } else {
            data.data[i].status = "Inactive";
            data.data[i].state = "white";
          }
        }
        mainAccounts.value = data.data;
      });
    }

    const switchActive = async (item) => {
      item.active = item.active === 1 ? 0 : 1;
      const data = await store.dispatch(Actions.SET_ACCOUNT_ACTIVE, item);
      // update local value
      if (mainAccounts.value) {
        let _mainAccounts: any = [];
        _mainAccounts = mainAccounts.value;
        for (let i = 0; i < _mainAccounts?.length; i++) {
          if (_mainAccounts[i].id === item.id) {
            // switch active
            if (_mainAccounts[i].active === 1) {
              // _mainAccounts[i].active = 1;
              _mainAccounts[i].status = "Active";
              _mainAccounts[i].state = "success";
            } else {
              // _mainAccounts[i].active = 0;
              _mainAccounts[i].status = "Inactive";
              _mainAccounts[i].state = "white";
            }
          }
        }
        mainAccounts.value = _mainAccounts;
      }
    };

    async function deleteAccount(item) {
      Swal.fire({
        title: "Are you sure?",
        icon: "info",
        confirmButtonText: "Yes",
        showCancelButton: true,
      }).then(async (result) => {
        if (result["isConfirmed"]) {
          await store.dispatch(Actions.DELETE_ACCOUNT, item.id);
          const response = await store.dispatch(Actions.GET_ACCOUNT);
          mainAccounts.value = response.data;
        }
      });
    }

    // eslint-disable-next-line no-undef
    onMounted(() => {
      fetchData();
    });
    return {
      checked,
      deleteAccount,
      mainAccounts,
      switchActive,
    };
  },
});
