
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import store from "@/store";

interface NewMainAccountData {
  name: string;
  apikey: string;
  secret: string;
  exchangename: string;
  exchangeProduct: string;
  subaccount: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  methods: {},
  props: { fetchDataParent: Function },
  watch: {
    newMainAccountData: {
      handler(newVal) {
        console.log(`newVal: ${JSON.stringify(newVal.exchangename)}`);
        const exchangeProductsMapping = {
          Bitmex: "XBTUSD",
          bitmexTEST: "XBTUSD",
          Bybit: "USD InvPerp",
          BybitTEST: "USD InvPerp",
          FTXInt: "BTC-PERP",
        };
        this.exchangeProducts = [exchangeProductsMapping[newVal.exchangename]];
        // check for FTX SubAccounts
        if (newVal.exchangename === "FTXInt") {
          // get the subaccounts...
          console.log(`getting sub accounts for FTX`);
          store
            .dispatch(Actions.GET_SUBACCOUNTSFTX, {
              apikey: this.newMainAccountData.apikey,
              secret: this.newMainAccountData.secret,
            })
            .then((result) => {
              if (result) {
                this.FTXsubaccounts = result.map(
                  (x) => x.nickname as string
                ) as Array<string>;
              } else {
                this.FTXsubaccounts = [
                  "please provide a valid apikey and secret for FTX...",
                ];
              }
            });
        }
      },
      deep: true,
    },
  },
  setup(props) {
    const store = useStore();

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);

    const newMainAccountData = ref<NewMainAccountData>({
      name: "",
      apikey: "",
      secret: "",
      exchangename: "",
      exchangeProduct: "",
      subaccount: "",
    });

    const exchangeProducts = ["please choose an exchange first."];
    const FTXsubaccounts: Array<string> = [
      "please provide a valid apikey and secret for FTX...",
    ];
    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("name"),
      apikey: Yup.string().required().label("apikey"),
      secret: Yup.string().required().label("secret"),
      exchangename: Yup.string().required().label("exchangename"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      store
        .dispatch(Actions.CREATE_ACCOUNT, newMainAccountData.value)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(function (data) {
          let message = "Account has been added";
          let timer = 3000;
          let ok = true;
          if (data.data === "NOK") {
            message =
              "Account is invalid, please check apikey, secret and/or exchange again...";
            timer = 8000;
            ok = false;
          }
          if (ok) {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
          }
          Swal.fire({
            title: message,
            html: "I will close in <b></b> milliseconds.",
            timer: timer,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
          }).then(() => {
            if (ok) {
              hideModal(newAddressModalRef.value);
              if (props.fetchDataParent) {
                props.fetchDataParent();
              }
            }
          });
        });
    };

    return {
      newMainAccountData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      exchangeProducts,
      FTXsubaccounts,
    };
  },
});
