<template>
  <tableMainAccountOverview
    widget-classes="mb-5 mb-xl-8"
  ></tableMainAccountOverview>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted } from "vue";
import KTChartWidget1 from "@/components/widgets/charts/Widget1.vue";
import KTListWidget5 from "@/components/widgets/lists/Widget5.vue";
import KTTableWidget5 from "@/components/widgets/tables/Widget5.vue";
import KTListWidget1 from "@/components/widgets/lists/Widget1.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import tableMainAccountOverview from "@/components/widgets/tables/tradecopy/tableMainAccountOverview.vue";
// import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "account-overview",
  components: {
    tableMainAccountOverview,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Account"]);
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // computed(() => {
    // // eslint-disable-next-line vue/no-async-in-computed-properties
    // store.dispatch(Actions.GETACCOUNT).then(function (data) {
    //   return data;
    // });
    // return true;
    // });
  },
});
</script>
