<template>
  <!--begin::Tables Widget 9-->

  <KTNewAccountModal :fetchDataParent="fetchData"></KTNewAccountModal>
  <div class="card" :class="widgetClasses">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">{{
          $t("mainAccounts")
        }}</span>

        <span class="text-muted mt-1 fw-bold fs-7"
          >When set to active, these exchange accounts will be monitored for new
          orders and any order will be copied to active child accounts.
        </span>
      </h3>
      <KTModalCard
        title="New Address Modal Example"
        description="Click on the below buttons to launch <br/>a new address example."
        image="media/illustrations/sketchy-1/19.png"
        button-text="Add New Account"
        modal-id="kt_modal_new_address"
      ></KTModalCard>
      <!--      <div-->
      <!--        class="card-toolbar"-->
      <!--        data-bs-toggle="tooltip"-->
      <!--        data-bs-placement="top"-->
      <!--        data-bs-trigger="hover"-->
      <!--        title="Click to add an account"-->
      <!--      >-->
      <!--        <a-->
      <!--          href="#"-->
      <!--          class="btn btn-sm btn-light-primary"-->
      <!--          data-bs-toggle="modal"-->
      <!--          data-bs-target="#kt_modal_invite_friends"-->
      <!--          @click="createAccount()"-->
      <!--        >-->
      <!--          <span class="svg-icon svg-icon-3">-->
      <!--            <inline-svg src="media/icons/duotune/arrows/arr075.svg" />-->
      <!--          </span>-->
      <!--          New Account-->
      <!--        </a>-->
      <!--      </div>-->
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">
        <!--begin::Table-->
        <table
          class="
            table table-row-dashed table-row-gray-300
            align-middle
            gs-0
            gy-4
          "
        >
          <!--begin::Table head-->
          <thead>
            <tr class="fw-bolder text-muted">
              <th class="w-25px">
                <div
                  class="
                    form-check form-check-sm form-check-custom form-check-solid
                  "
                >
                  <!--                  <input class="form-check-input" type="checkbox" value="1" />-->
                </div>
              </th>
              <th class="min-w-150px">Account name</th>
              <th class="min-w-140px">Secret</th>
              <th class="min-w-120px">Exchange</th>
              <th class="min-w-120px">Active</th>
              <th class="min-w-100px text-end">Actions</th>
            </tr>
          </thead>
          <!--end::Table head-->

          <!--begin::Table body-->
          <tbody>
            <template v-for="(item, index) in mainAccounts" :key="index">
              <tr>
                <td>
                  <div
                    class="
                      form-check
                      form-check-sm
                      form-check-custom
                      form-check-solid
                    "
                  >
                    <!--                    <input-->
                    <!--                      class="form-check-input widget-9-check"-->
                    <!--                      type="checkbox"-->
                    <!--                      :value="index"-->
                    <!--                    />-->
                  </div>
                </td>

                <td>
                  <div class="d-flex align-items-center">
                    <!--                    <div class="symbol symbol-45px me-5">-->
                    <!--                      <img :src="item.image" alt="" />-->
                    <!--                    </div>-->
                    <div class="d-flex justify-content-start flex-column">
                      <a class="text-dark fw-bolder text-hover-primary fs-6">{{
                        item.name
                      }}</a>

                      <span
                        class="text-muted fw-bold text-muted d-block fs-7"
                        >{{ item.apikey }}</span
                      >
                    </div>
                  </div>
                </td>

                <td>
                  <a
                    class="text-dark fw-bolder text-hover-primary d-block fs-6"
                    >{{ item.secret }}</a
                  >
                  <!--                  <span class="text-muted fw-bold text-muted d-block fs-7">{{-->
                  <!--                    item.companySkills-->
                  <!--                  }}</span>-->
                </td>

                <td>
                  <a
                    class="text-dark fw-bolder text-hover-primary d-block fs-6"
                    >{{ item.exchangename }}</a
                  >
                </td>
                <td>
                  <span
                    data-bs-placement="top"
                    title="Tooltip on top"
                    :class="`badge badge-${item.state}`"
                    data-bs-toggle="tooltip"
                    >{{ item.status }}</span
                  >
                </td>
                <td class="text-end">
                  <!--                  <span class="svg-icon svg-icon-3">-->
                  <!--                    <inline-svg src="media/icons/duotune/general/gen019.svg" />-->
                  <!--                  </span>-->
                  <!--                  edit-->
                  <a
                    style="margin-left: 5px"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span
                      data-bs-placement="top"
                      title="This button allows you to switch the status between active/inactive for syncing the action by the main account to the copy account."
                      data-bs-toggle="tooltip"
                      class="svg-icon svg-icon-3"
                      @click="switchActive(item)"
                    >
                      <inline-svg src="media/icons/duotune/arrows/arr029.svg" />
                    </span>
                  </a>
                  <a
                    style="margin-left: 5px"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span
                      class="svg-icon svg-icon-3"
                      @click="editAccount(item)"
                    >
                      <inline-svg src="media/icons/duotune/art/art005.svg" />
                    </span>
                  </a>
                  <!--delete-->
                  <a
                    style="margin-left: 5px"
                    class="
                      btn btn-icon btn-bg-light btn-active-color-primary btn-sm
                    "
                  >
                    <span
                      class="svg-icon svg-icon-3"
                      @click="deleteAccount(item)"
                    >
                      <inline-svg
                        src="media/icons/duotune/general/gen027.svg"
                      />
                    </span>
                  </a>
                </td>
              </tr>
            </template>
          </tbody>
          <!--end::Table body-->
        </table>
        <!--end::Table-->
      </div>
      <!--end::Table container-->
    </div>
    <!--begin::Body-->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import KTModalCard from "@/components/cards/CardMainAccount.vue";
import KTNewAccountModal from "@/components/modals/forms/NewAccountModal.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "kt-widget-9",
  components: {
    KTModalCard,
    KTNewAccountModal,
  },
  props: {
    widgetClasses: String,
  },
  methods: {
    editAccount(item) {
      // this.$router.push({ path: "/editmainaccount" });
      this.$router.push({ path: "/editmainaccount/" + item.id });
    },
    createAccount() {
      // this.$router.push({ path: "/createmainaccount" });
    },
    fetchData() {
      this.$store.dispatch(Actions.GET_ACCOUNT).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          const item = data.data[i];
          if (item.active === 1) {
            data.data[i].status = "Active";
            data.data[i].state = "success";
          } else {
            data.data[i].status = "Inactive";
            data.data[i].state = "white";
          }
        }
        this.mainAccounts = data.data;
      });
    },
    // switchActive (item)  {
    //   const data = await this.$store.dispatch(Actions.SET_ACCOUNT_ACTIVE, item);
    //   // update local value
    //   for (let i = 0; i < this.mainAccounts.value.length; i++) {
    //     if (copyAccounts.value[i].id === item.id) {
    //       // switch active
    //       if (copyAccounts.value[i].active === 0) {
    //         copyAccounts.value[i].active = 1;
    //         copyAccounts.value[i].status = "Active";
    //         copyAccounts.value[i].state = "success";
    //       } else {
    //         copyAccounts.value[i].active = 0;
    //         copyAccounts.value[i].status = "Inactive";
    //         copyAccounts.value[i].state = "white";
    //       }
    //     }
    //   }
    // };
  },
  setup() {
    const store = useStore();
    const checked = ref(false);
    const mainAccounts = ref(null);

    function fetchData() {
      store.dispatch(Actions.GET_ACCOUNT).then((data) => {
        for (let i = 0; i < data.data.length; i++) {
          const item = data.data[i];
          if (item.active === 1) {
            data.data[i].status = "Active";
            data.data[i].state = "success";
          } else {
            data.data[i].status = "Inactive";
            data.data[i].state = "white";
          }
        }
        mainAccounts.value = data.data;
      });
    }

    const switchActive = async (item) => {
      item.active = item.active === 1 ? 0 : 1;
      const data = await store.dispatch(Actions.SET_ACCOUNT_ACTIVE, item);
      // update local value
      if (mainAccounts.value) {
        let _mainAccounts: any = [];
        _mainAccounts = mainAccounts.value;
        for (let i = 0; i < _mainAccounts?.length; i++) {
          if (_mainAccounts[i].id === item.id) {
            // switch active
            if (_mainAccounts[i].active === 1) {
              // _mainAccounts[i].active = 1;
              _mainAccounts[i].status = "Active";
              _mainAccounts[i].state = "success";
            } else {
              // _mainAccounts[i].active = 0;
              _mainAccounts[i].status = "Inactive";
              _mainAccounts[i].state = "white";
            }
          }
        }
        mainAccounts.value = _mainAccounts;
      }
    };

    async function deleteAccount(item) {
      Swal.fire({
        title: "Are you sure?",
        icon: "info",
        confirmButtonText: "Yes",
        showCancelButton: true,
      }).then(async (result) => {
        if (result["isConfirmed"]) {
          await store.dispatch(Actions.DELETE_ACCOUNT, item.id);
          const response = await store.dispatch(Actions.GET_ACCOUNT);
          mainAccounts.value = response.data;
        }
      });
    }

    // eslint-disable-next-line no-undef
    onMounted(() => {
      fetchData();
    });
    return {
      checked,
      deleteAccount,
      mainAccounts,
      switchActive,
    };
  },
});
</script>
