<template>
  <!--begin::Modal - New Address-->
  <div
    class="modal fade"
    ref="newAddressModalRef"
    id="kt_modal_new_address"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Form-->
        <Form
          class="form"
          id="kt_modal_new_address_form"
          @submit="submit"
          :validation-schema="validationSchema"
        >
          <!--begin::Modal header-->
          <div class="modal-header" id="kt_modal_new_address_header">
            <!--begin::Modal title-->
            <h2>Add New Main Account</h2>
            <!--end::Modal title-->

            <!--begin::Close-->
            <div
              class="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
            >
              <span class="svg-icon svg-icon-1">
                <inline-svg src="media/icons/duotune/arrows/arr061.svg" />
              </span>
            </div>
            <!--end::Close-->
          </div>
          <!--end::Modal header-->

          <!--begin::Modal body-->
          <div class="modal-body py-10 px-lg-17">
            <!--begin::Scroll-->
            <div
              class="scroll-y me-n7 pe-7"
              id="kt_modal_new_address_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_new_address_header"
              data-kt-scroll-wrappers="#kt_modal_new_address_scroll"
              data-kt-scroll-offset="300px"
            >
              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Account name</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  class="form-control form-control-solid"
                  placeholder=""
                  name="address1"
                  v-model="newMainAccountData.name"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="address1" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Label-->
              <label class="required fs-5 fw-bold mb-2">Exchange</label>
              <!--begin::Input group-->
              <Field
                name="exchange"
                class="form-select form-select-solid"
                as="select"
                v-model="newMainAccountData.exchangename"
              >
                <option value="">Select an Exchange...</option>
                <option value="FTXInt">FTX.com</option>
                <option value="bitmexTEST">Bitmex test</option>
                <option value="Bitmex">Bitmex</option>
                <option value="Bybit">Bybit</option>
                <option value="BybitTEST">Bybit test</option> </Field
              ><br /><br />
              <!--end::Input group-->

              <!--begin::Label-->
              <label class="required fs-5 fw-bold mb-2"
                >Exchange product (to watch and copy)</label
              >
              <!--begin::Input group-->
              <Field
                name="exchangeProduct"
                class="form-select form-select-solid"
                as="select"
                v-model="newMainAccountData.exchangeProduct"
              >
                <option
                  v-for="(item, index) in exchangeProducts"
                  :key="item"
                  :value="item"
                  :selected="index === 0"
                >
                  {{ item }}
                </option> </Field
              ><br />

              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Apikey</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  class="form-control form-control-solid"
                  placeholder=""
                  name="address2"
                  v-model="newMainAccountData.apikey"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="apikey" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div class="d-flex flex-column mb-5 fv-row">
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2">Secret</label>
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  class="form-control form-control-solid"
                  placeholder=""
                  name="Secret"
                  v-model="newMainAccountData.secret"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="secret" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->

              <!--begin::Input group-->
              <div
                v-if="newMainAccountData.exchangename === 'FTXInt'"
                class="d-flex flex-column mb-5 fv-row"
              >
                <!--begin::Label-->
                <label class="required fs-5 fw-bold mb-2"
                  >Sub Account (FTX only)</label
                >
                <!--end::Label-->

                <!--begin::Input-->
                <Field
                  name="subaccount"
                  class="form-select form-select-solid"
                  as="select"
                  v-model="newMainAccountData.subaccount"
                >
                  <option
                    v-for="(item, index) in FTXsubaccounts"
                    :key="item"
                    :value="item"
                    :selected="index === 0"
                  >
                    {{ item }}
                  </option></Field
                >
                <br /><br />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage name="secret" />
                  </div>
                </div>
                <!--end::Input-->
              </div>
              <!--end::Input group-->
            </div>
            <!--end::Scroll-->
          </div>
          <!--end::Modal body-->

          <!--begin::Modal footer-->
          <div class="modal-footer flex-center">
            <!--begin::Button-->
            <button
              type="reset"
              id="kt_modal_new_address_cancel"
              class="btn btn-white me-3"
            >
              Discard
            </button>
            <!--end::Button-->

            <!--begin::Button-->
            <button
              ref="submitButtonRef"
              type="submit"
              id="kt_modal_new_address_submit"
              class="btn btn-primary"
              @click="submit"
            >
              <span class="indicator-label"> Submit </span>
              <span class="indicator-progress">
                Please wait...
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
            <!--end::Button-->
          </div>
          <!--end::Modal footer-->
        </Form>
        <!--end::Form-->
      </div>
    </div>
  </div>
  <!--end::Modal - New Address-->
</template>

<script lang="ts">
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useStore } from "vuex";
import store from "@/store";

interface NewMainAccountData {
  name: string;
  apikey: string;
  secret: string;
  exchangename: string;
  exchangeProduct: string;
  subaccount: string;
}

export default defineComponent({
  name: "new-address-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  methods: {},
  props: { fetchDataParent: Function },
  watch: {
    newMainAccountData: {
      handler(newVal) {
        console.log(`newVal: ${JSON.stringify(newVal.exchangename)}`);
        const exchangeProductsMapping = {
          Bitmex: "XBTUSD",
          bitmexTEST: "XBTUSD",
          Bybit: "USD InvPerp",
          BybitTEST: "USD InvPerp",
          FTXInt: "BTC-PERP",
        };
        this.exchangeProducts = [exchangeProductsMapping[newVal.exchangename]];
        // check for FTX SubAccounts
        if (newVal.exchangename === "FTXInt") {
          // get the subaccounts...
          console.log(`getting sub accounts for FTX`);
          store
            .dispatch(Actions.GET_SUBACCOUNTSFTX, {
              apikey: this.newMainAccountData.apikey,
              secret: this.newMainAccountData.secret,
            })
            .then((result) => {
              if (result) {
                this.FTXsubaccounts = result.map(
                  (x) => x.nickname as string
                ) as Array<string>;
              } else {
                this.FTXsubaccounts = [
                  "please provide a valid apikey and secret for FTX...",
                ];
              }
            });
        }
      },
      deep: true,
    },
  },
  setup(props) {
    const store = useStore();

    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const newAddressModalRef = ref<null | HTMLElement>(null);

    const newMainAccountData = ref<NewMainAccountData>({
      name: "",
      apikey: "",
      secret: "",
      exchangename: "",
      exchangeProduct: "",
      subaccount: "",
    });

    const exchangeProducts = ["please choose an exchange first."];
    const FTXsubaccounts: Array<string> = [
      "please provide a valid apikey and secret for FTX...",
    ];
    const validationSchema = Yup.object().shape({
      name: Yup.string().required().label("name"),
      apikey: Yup.string().required().label("apikey"),
      secret: Yup.string().required().label("secret"),
      exchangename: Yup.string().required().label("exchangename"),
    });

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }
      store
        .dispatch(Actions.CREATE_ACCOUNT, newMainAccountData.value)
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .then(function (data) {
          let message = "Account has been added";
          let timer = 3000;
          let ok = true;
          if (data.data === "NOK") {
            message =
              "Account is invalid, please check apikey, secret and/or exchange again...";
            timer = 8000;
            ok = false;
          }
          if (ok) {
            if (submitButtonRef.value) {
              submitButtonRef.value.disabled = false;

              submitButtonRef.value?.removeAttribute("data-kt-indicator");
            }
          }
          Swal.fire({
            title: message,
            html: "I will close in <b></b> milliseconds.",
            timer: timer,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading();
              const b = Swal.getHtmlContainer().querySelector("b");
              setInterval(() => {
                b.textContent = Swal.getTimerLeft();
              }, 100);
            },
          }).then(() => {
            if (ok) {
              hideModal(newAddressModalRef.value);
              if (props.fetchDataParent) {
                props.fetchDataParent();
              }
            }
          });
        });
    };

    return {
      newMainAccountData,
      validationSchema,
      submit,
      submitButtonRef,
      newAddressModalRef,
      exchangeProducts,
      FTXsubaccounts,
    };
  },
});
</script>
